<template>
    <div>
        <ts-page-title
            :title="$t('basicSalaryFormula.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('basicSalaryFormula.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <button></button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="shift_name">
                    {{
                        row.shift_management
                            ? row.shift_management.shift_name
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="created_by">
                    {{
                        row.created_by
                            ? row.created_by.employee
                                ? row.created_by.employee.employee_name_en
                                : row.created_by.user_name
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="modified_by">
                    {{
                        row.modified_by
                            ? row.modified_by.employee
                                ? row.modified_by.employee.employee_name_en
                                : row.modified_by.user_name
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="is_end_of_month">
                    <span>
                        <span
                            v-if="row.is_end_of_month"
                            class="ios-checkmark-circle-outline badge bg-success"
                            >Yes</span
                        >
                        <span
                            v-else
                            class="ios-close-circle-outline badge bg-danger"
                            >NO</span
                        >
                    </span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('basicSalaryFormula.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import FromAction from './form.vue'
import { debounce } from 'lodash'

export default {
    name: 'basic-salary-formula',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            showForm: false
        }
    },
    computed: {
        ...mapState('humanResource/basicSalaryFormula', [
            'resources',
            'pagination'
        ]),
        search: {
            get () {
                return this.$store.state.humanResource.basicSalaryFormula.search
            },
            set (newValue) {
                this.$store.commit(
                    'humanResource/basicSalaryFormula/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'humanResource/basicSalaryFormula/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('basicSalaryFormula.shiftName'),
                    slot: 'shift_name',
                    minWidth: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalaryFormula.salaryPerDay'),
                    key: 'salary_per_day',
                    minWidth: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalaryFormula.salaryPerHours'),
                    key: 'salary_per_hour',
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t('basicSalaryFormula.totalDays'),
                    key: 'total_days',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: this.$t('basicSalaryFormula.totalHours'),
                    key: 'total_hours',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: this.$t('basicSalaryFormula.endOfMonth'),
                    key: 'is_end_of_month',
                    minWidth: 170,
                    slot: 'is_end_of_month',
                    align: 'center',
                    filters: [
                        {
                            label: 'Enable',
                            value: false
                        },
                        {
                            label: 'Disable',
                            value: true
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                is_end_of_month: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('basicSalaryFormula.createdBy'),
                    slot: 'created_by',
                    minWidth: 130,
                    sortable: true
                },
                {
                    title: this.$t('basicSalaryFormula.modifiedBy'),
                    slot: 'modified_by',
                    minWidth: 130
                },
                {
                    title: this.$t('basicSalaryFormula.createDat'),
                    key: 'created_date',
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t('basicSalaryFormula.modifiedDate'),
                    key: 'modified_date',
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('humanResource/basicSalaryFormula/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            this.showForm = true
            this.$store.commit(
                'humanResource/basicSalaryFormula/SET_EDIT_DATA',
                record
            )
            this.$refs.form_action.fetchResource()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('humanResource/basicSalaryFormula/CLEAR_STATE')
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BASIC SALARY FORMULA',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
