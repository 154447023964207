var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('ts-panel',{staticClass:" note alert alert-primary fade show tw-rounded-md"},[_c('ts-panel-wrapper',[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.parameter')))]),_c('Tooltip',{attrs:{"placement":"top"}},[_c('i',{staticClass:"fas fa-question-circle  tw-ml-3 has-tooltip"}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"tw-text-xs"},[_vm._v(" Use this parameters to create formula ")])])]),_c('div',{staticClass:"mb-3"},[[_c('Tooltip',{attrs:{"placement":"top"}},[_vm._l((_vm.basicSalaryFormulaParameters),function(basicSalaryFormulaParameter,index){return _c('a',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                                        basicSalaryFormulaParameter.param_name
                                    ),expression:"\n                                        basicSalaryFormulaParameter.param_name\n                                    ",arg:"copy"}],key:index,staticClass:"tw-p-2 tw-no-underline tw-text-black tw-text-xs",attrs:{"value":basicSalaryFormulaParameter.param_name},on:{"click":_vm.copied}},[_vm._v(_vm._s(basicSalaryFormulaParameter.param_name))])}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"tw-text-xs"},[_vm._v(" Click to copy parameter ")])])],2)]],2)],1)])],1),_c('div',{staticClass:"mb-3 row"},[_c('label',{staticClass:"form-label col-label col-md-4 mt-1 required"},[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.salaryPerDay')))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.salary_per_day),expression:"model.salary_per_day"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.errors.has('salary_per_day')
                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.salary_per_day)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "salary_per_day", $event.target.value)}}}),(_vm.errors.has('salary_per_day'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary_per_day'))+" ")]):_vm._e(),_c('i',{staticClass:" tw-text-xs"},[_vm._v("( e.g {base_salary} * {total_days} )")])])]),_c('div',{staticClass:"mb-3 row"},[_c('label',{staticClass:"form-label col-label col-md-4 mt-1 required"},[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.salaryPerHours')))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.salary_per_hour),expression:"model.salary_per_hour"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.errors.has('salary_per_hour')
                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.salary_per_hour)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "salary_per_hour", $event.target.value)}}}),(_vm.errors.has('salary_per_hour'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary_per_hour'))+" ")]):_vm._e(),_c('i',{staticClass:"tw-text-xs"},[_vm._v("( e.g {base_salary} * {total_hours} )")])])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-label col-md-4"},[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.endOfMonth')))]),_c('div',{staticClass:"col-md-8"},[_c('Checkbox',{on:{"on-change":function () { return (_vm.model.total_days = _vm.model.total_hours = null); }},model:{value:(_vm.model.is_end_of_month),callback:function ($$v) {_vm.$set(_vm.model, "is_end_of_month", $$v)},expression:"model.is_end_of_month"}})],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-label col-md-4 ",class:{ required: !_vm.model.is_end_of_month }},[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.totalDays')))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.total_days),expression:"model.total_days"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.errors.has('total_days')
                    },attrs:{"disabled":_vm.model.is_end_of_month,"type":"number"},domProps:{"value":(_vm.model.total_days)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "total_days", $event.target.value)}}}),(_vm.errors.has('total_days'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('total_days'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"form-label col-label col-md-4 ",class:{ required: !_vm.model.is_end_of_month }},[_vm._v(_vm._s(_vm.$t('basicSalaryFormulaParameter.totalHours')))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.total_hours),expression:"model.total_hours"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.errors.has('total_hours')
                    },attrs:{"disabled":_vm.model.is_end_of_month,"type":"number"},domProps:{"value":(_vm.model.total_hours)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "total_hours", $event.target.value)}}}),(_vm.errors.has('total_hours'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('total_hours'))+" ")]):_vm._e()])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update')))])],1)])],1),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }